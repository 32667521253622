var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"main-671bc16c"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

if (process.env.NODE_ENV !== "development") {
	Sentry.init({
		dsn: SENTRY_DSN,
		tracesSampleRate: 1.0,
		environment: process.env.NEXT_PUBLIC_CLOUD_ENV,
		release: process.env.NEXT_PUBLIC_SENTRY_RELEASE,
		autoSessionTracking: true,
		replaysSessionSampleRate: 0,
		replaysOnErrorSampleRate: 1,
		integrations: [
			new Sentry.Replay({
				maskAllInputs: false,
				maskAllText: false,
				blockAllMedia: false,
				collectFonts: true,
				recordCanvas: false,
				inlineImages: false,
			}),
		],
		beforeSend(event, hint) {
			// Check if it is an exception, and if so, show the report dialog
			if (event.exception) {
				Sentry.showReportDialog({ eventId: event.event_id });
			}
			return event;
		},
	});
}
